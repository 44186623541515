import React from 'react';


// components
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import FormPrenotazioni from '../components/FormPrenotazioni';

import {Container, Col, Row} from 'react-bootstrap';


var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');


const scrollToTop = () => {
    let c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};


export default class Contatti extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            thispage: "contatti",
            scroll: 0,
            sidebarState: false,
            viewport: {
                width: '100%',
                height: 300,
                latitude: 7.647013,
                longitude: 45.401349,
                zoom: 8
            },
            form:{

            }
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuIconClick = this.handleMenuIconClick.bind(this);
    }

    // valuto lo scrolling e la distanza dal TOP
    handleScroll(event){        
        let offsettop = event.srcElement.scrollingElement.scrollTop;
        this.setState({ scroll: offsettop });
    }

    // il click sull'icona in ALTO nella sidebar, gestito in Topbar.js, deve mostrare il Sidebar.js.
    handleMenuIconClick(){
        console.log("handleMenuIconClick()");
        this.setState({
            sidebarState: !this.state.sidebarState
        },() => {
            console.log("nuovo val stato: "+ this.state.sidebarState);
        });
    }

    

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);

        mapboxgl.accessToken = 'pk.eyJ1IjoiYWxlbXV4IiwiYSI6ImNrMWo1aXY2bzA3dDUzaGw5aHV5YTRldGkifQ.Q-lWrDVmyHnvKgArTzXAig';
        var map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [ 7.647013,45.401349],            
            zoom: 12,
           
            layout: {
                "icon-image": "icon-15",
                "icon-allow-overlap": true      
            }
        });
        map.scrollZoom.disable();
        map.boxZoom.disable();
        map.dragRotate.disable();
        map.dragPan.disable();
        map.keyboard.disable();
        map.doubleClickZoom.disable();
        map.touchZoomRotate.disable();


        map.on('load', function() {
            map.loadImage('/images/map.png', function(error, image) {
                if (error) throw error;
                map.addImage('cat', image);
                map.addLayer({
                    "id": "points",
                    "type": "symbol",
                    "source": {
                        "type": "geojson",
                        "data": {
                            "type": "FeatureCollection",
                            "features": [{
                                "type": "Feature",
                                "geometry": {
                                    "type": "Point",
                                    "coordinates": [ 7.647013,45.401349]
                                }
                            }]
                        }
                    },
                    "layout": {
                        "icon-image": "cat",
                        "icon-size": 1
                    }
                });
            });
        });

    }
    
    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    render(){

        let topbarStyle = false, arrowUpToggle;
        if(this.state.scroll!==0){
            topbarStyle = true;
            arrowUpToggle = 'shown';
        }

        return(
            <div className="globalContainer">
                            
                <Sidebar location={this.state.thispage} sidebarState={this.state.sidebarState} handleClose={this.handleMenuIconClick} />
                <Topbar location={this.state.thispage} minified={topbarStyle} menuMobileIconClick={this.handleMenuIconClick}  />
                <div id="arrowUpWindowManager" className={arrowUpToggle} onClick={() => scrollToTop()} >
                <i className="material-icons">
                play_for_work
                </i></div>

                
                <div className="gradient1">
                    <Container>
                        <Row>

                            <Col sm={12} md={8} className="footer_col_sx">
                            
                                <FormPrenotazioni />
                            
                            </Col>
                            <Col sm={12} md={4}>
                            
                                <div className="">
                                    <h2><i className="material-icons">contact_phone</i> Come contattarci</h2>
                                    <p>Telefono: <a href="tel:0124651669" title="Contattaci per avere tutte le informazioni">0124/651669</a></p>
                                    <p>E-Mail: <a href="mailto:info@il-gommista.it" title="Scrivici!">info@il-gommista.it</a></p>
                                   
                                </div>  

                            </Col>

                        </Row>
                        

                        
                    </Container>                
                </div>
                <div id="map" className="map"></div>
                <Footer location={this.state.thispage} />

            </div>
        )
    }
}

