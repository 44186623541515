import React from 'react';
import { Link } from "react-router-dom";
import {Container, Col, Row} from 'react-bootstrap';



export default class Footer extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){

        let page = this.props.location;
        let class_homepage = '', class_contatti='';
        switch(page){
            case "homepage":
                class_homepage = "active";
                break;
            case "contatti":
                class_contatti = "active";
                break;
            default:
                break;
        }

        return(
            <div>
            <div className="footer">                                
                <Container>
                    <Row>
                        <Col sm={12} md={4} className="footer_col_sx">
                            <img src="/images/il-gommista-insegna-stmp-copia-2.png" alt="Il Gommista di Perardi Igor" />
                        </Col>
                        <Col sm={12} md={4} >
                            <div className="footer_subcol">
                            <h3>Mappa del sito</h3>
                            <div className={class_homepage}><Link to={'/'}>Homepage</Link></div>
                            <div className={class_contatti}><Link to={'/contatti'}>Contatti</Link></div>
                            </div>
                        </Col>
                        <Col sm={12} md={4} >
                            <div className="footer_subcol">
                            <h3>Il Gommista di Perardi Igor</h3>
                            <p>Ci potete trovare in Via Fratelli Rosselli 73 a Cuorgnè</p>
                            <p><a rel="noopener noreferrer" href="https://www.google.com/maps/place/Il+Gommista+di+Perardi+Igor/@45.4013524,7.6470069,15z/data=!4m2!3m1!1s0x0:0x68cb7e02f6b4467b?sa=X&ved=2ahUKEwiyncuIyY_lAhUPMewKHZgQBiYQ_BIwCnoECA8QCA" target="_blank">Se vuoi trovarci su GoogleMaps, clicca qui</a></p>

                            <h4>Contatti</h4>
                            <p>Telefono: <a href="tel:0124651669">0124/651669</a></p>

                            <h4>Orari</h4>
                            <p>Lunedi Venerdi: 08.00 - 12 / 14.00 - 19.00<br />Sabato: 08:00 - 12.00</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="subfooter">
                <p>Il Gommista di Perardi Igor - PIVA: 07796500010 - <Link to={'/cookie'}>Cookie policy</Link> - <Link to={'/privacy'}>Privacy policy</Link></p>
            </div>
            </div>
        )
    }
}