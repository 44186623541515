import React from 'react';
import { Link } from "react-router-dom";


export default class Topbar extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = { 
            class_homepage: "",
            class_contatti: "",
            class_ebike: ""
        };
        
    }



    render(){

        let page = this.props.location;
        let classes = this.state;
        let minified = "";
        //if(this.props.minified){
        //    minified = "minified";
        //}

        switch(page){
            case "homepage":
                classes.class_homepage = "active";
                break;
            case "contatti":
                classes.class_contatti = "active";
                break;
            case "ebike":
                classes.class_ebike = "active";
                break;
            default:
                break;
        }

        return(
            <div className={"topbar "+ minified}>
                <div className="mobileMenuContainer"><i onClick={() => this.props.menuMobileIconClick()} className="material-icons">menu_open</i></div>
                <Link to={"/"}><div className="logo"></div></Link>
                <div className="links">
                
                    <ul>
                        <li className={classes.class_homepage}><Link to="/">Homepage</Link></li>
                        <li className={classes.class_ebike}><Link to="/ebike">Noleggio e Vendita E-Bike</Link></li>                        
                        <li className={classes.class_contatti}><Link to="/contatti">Contatti</Link></li>                        
                    </ul>
                </div>
            </div>
        )
    }
}