import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollTopTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './css/mediaquery.css';

import Home from './screens/Homepage';
import Contatti from './screens/Contatti'; 
import Privacy from './screens/Privacy';
import Cookie from './screens/Cookie';
import Ebike from './screens/Ebike';

function App() {
  return (
    <div>
    
    <Router>
    
      <ScrollTopTop />
      <div>        
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>

          <Route path="/contatti">
            <Helmet>
              <title>Contattaci senza impegno per un preventivo</title>
              <meta name="description" content="Se vuoi prenotare o solamente avere informazioni, contattaci via telefono o email oppure passa a trovarci!" />
              <meta property="og:title" content="Contattaci senza impegno per un preventivo" />
              <meta property="og:image" content="/images/IMG_6884.jpg" />
            </Helmet>
            <Contatti />
          </Route>

          <Route path="/ebike">
            <Helmet>
              <title>Noleggio e Vendita E-Bike in Canavese</title>
              <meta name="description" content="Se sei appassionato di biciclette elettriche non puoi non venire a visitarci per visionare tutti i nostri modelli" />
              <meta property="og:title" content="Noleggio e Vendita EBike in Canavese" />
              <meta property="og:image" content="/images/IMG_6884.jpg" />
            </Helmet>
            <Ebike />
          </Route>
          
          <Route path="/privacy">
            <Helmet>
              <title>Il Gommista: trattamento dei dati personali</title>
              <meta name="description" content="Tutte le informazioni su come gestiamo i vostri dati personali" />
              <meta property="og:title" content="Il Gommista: trattamento dei dati personali" />
              <meta property="og:image" content="/images/IMG_6884.jpg" />
            </Helmet>
            <Privacy />
          </Route>

          <Route path="/cookie">
            <Helmet>
              <title>Il Gommista: Cookie Policy</title>
              <meta name="description" content="ai sensi dell’Articolo 13 del Regolamento UE 2016/679" />
              <meta property="og:title" content="Il Gommista: Cookie Policy" />
              <meta property="og:image" content="/images/IMG_6884.jpg" />
            </Helmet>
            <Cookie />
          </Route>

          <Route path="/">
          <Helmet>
              <title>Il Gommista di Perardi Igor - A Cuorgnè il tuo gommista di fiducia</title>
              <meta name="description" content="Gomme e cerchi, tutto per auto e moto: vieni a trovarci ed affidati al nostro team di esperti" />
              <meta property="og:title" content="Il Gommista di Perardi Igor - A Cuorgnè il tuo gommista di fiducia" />
              <meta property="og:image" content="/images/IMG_6882.jpg" />
            </Helmet>
            <Home />
          </Route>                      

        </Switch>
      </div>
    </Router>
    </div>
  );
}

export default App;
