import React from 'react';
import { Link } from "react-router-dom";
import { Col,  Form, Button, FormCheck,  InputGroup} from 'react-bootstrap';

export default class FormPrenotazioni extends React.Component{

    constructor(props){
        super(props);
        this.state = {

            formContainerClass: "",

            nome: "",
            nomeValidation: false,

            cognome:"",
            cognomeValidation: false,

            email:"",
            emailValidation: false,

            tel:"",
            telValidation: false,

            testo:"",
            testoValidation: false,

            privacy: false,
            privacyValidation: false,

           
            buttonIsLoading: false,
            

        }
        this.resetSendBtn = this.resetSendBtn.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    resetSendBtn(){
        this.setState({
            buttonIsLoading: false
        });
    }

    handleChange(e){
          
        const name = e.target.id;
        const value = e.target.value;        
        const changeValueState = {
            [name]: value,
        }
        this.setState(changeValueState);

    }

    handleChangeCheckbox(e){
        console.log("handleChangeCheckbox()");
        console.log(e.target.id);

        let oldval = this.state[e.target.id];
        console.log("oldval => "+ oldval);
       
        this.setState({
            [e.target.id]: !oldval
        });

    }

    handleSubmit(){

        let valid = 1;
        let nomeValidation = false;
        let cognomeValidation = false;
        let telValidation = false;
        let emailValidation = false;
        let testoValidation = false;
        let privacyValidation = false;

        var formValues = this.state;
        console.log("formvalues", formValues);

        if(formValues.nome.length<2){
            nomeValidation = true;
            valid = 0;
        }

        if(formValues.cognome.length<2){
            cognomeValidation = true;
            valid = 0;
        }

        if(formValues.tel.length<2){
            telValidation = true;
            valid = 0;
        }

        if(formValues.email.length<2 || formValues.email.indexOf('.')<0 || formValues.email.indexOf('@')<0){
            emailValidation = true;
            valid = 0;
        }

        if(formValues.testo.length<2){
            testoValidation = true;
            valid = 0;
        }

        if(!formValues.privacy){
            privacyValidation = true;
            valid = 0;
        }

        if(valid===1){
            // form validated
            console.log("form validated");
            this.setState({
                buttonIsLoading: true,
                nomeValidation: false,
                cognomeValidation: false,
                telValidation: false,
                emailValidation: false,
                testoValidation: false,
                privacyValidation: false
            },() => {
                console.log("form send to server");

                // devo fare un bel fetch
                fetch('https://www2.madeincanavese.it/sendemail',{
                    method:'POST',
                    headers:{
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body:JSON.stringify(this.state)
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log(responseJson);
                    this.resetSendBtn();
                    
                    let status = responseJson.status;
                    if(status===200){
                        this.setState({
                            formContainerClass: "hidden"
                        });
                    }else{
                        alert("Si è verificato un errore nell'invio della mail.\nPuoi riprovare o scrivere direttamente la tua email a info@il-gommista.it");
                    }



                })
                .catch((error) => {
                    console.log("Errore nella chiamata al server", error);
                    this.resetSendBtn();
                });



            });

        }else{
            this.setState({
                nomeValidation: nomeValidation,
                cognomeValidation: cognomeValidation,
                telValidation: telValidation,
                emailValidation: emailValidation,
                testoValidation: testoValidation,
                privacyValidation: privacyValidation
            });
        }


    }

    render(){
        return(
            <Form className="form-compressed">

                <h2><i className="material-icons">assignment</i> Prenotazioni</h2>                        
                <p>Richiedi una prenotazione per il tuo cambio gomme</p>


                {(this.state.formContainerClass.length!==0)? <div className="fadeInDown delay-1s">Grazie per inviato la tua richiesta informazioni.<br />Ti risponderemo appena possibile!</div> :null}

                <div id="form-container" className={this.state.formContainerClass}>
                <Form.Row>
                    <Form.Group as={Col} controlId="nome">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Inserisci il tuo nome..." 
                        required
                        value={this.state.nome}
                        onChange={this.handleChange}
                        isInvalid={this.state.nomeValidation}
                        />
                        <Form.Control.Feedback type="invalid">Campo nome obbligatorio</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="cognome" >
                        <Form.Label>Cognome</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Inserisci il tuo cognome..." 
                        required      
                        isInvalid={this.state.cognomeValidation}
                        value={this.state.cognome}
                        onChange={this.handleChange}
                        />
                        <Form.Control.Feedback type="invalid">Campo cognome obbligatorio</Form.Control.Feedback>
                    </Form.Group>
                    
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="email">
                        <Form.Label>E-Mail</Form.Label>
                        <InputGroup>
                            <Form.Control 
                                type="email" 
                                placeholder="Inserisci il tuo indirizzo email..." 
                                required
                                isInvalid={this.state.emailValidation}
                                value={this.state.email}
                                onChange={this.handleChange}
                                />
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            </InputGroup.Prepend>
                            
                            <Form.Control.Feedback type="invalid">Email obbligatoria</Form.Control.Feedback>
                        </InputGroup>
                        
                    </Form.Group>

                    <Form.Group as={Col} controlId="tel" >
                        <Form.Label>Telefono</Form.Label>
                        <Form.Control 
                        type="tel" 
                        placeholder="Inserisci il tuo telefono..." 
                        required      
                        isInvalid={this.state.telValidation}                                     
                        value={this.state.tel}
                        onChange={this.handleChange}
                        />
                        <Form.Control.Feedback type="invalid">Telefono obbligatorio</Form.Control.Feedback>
                    </Form.Group>
                    
                    

                </Form.Row>

                
                    <Form.Group controlId="testo">
                        <Form.Label>Testo della tua richiesta</Form.Label>
                        <Form.Control 
                        as="textarea" 
                        rows="5" 
                        value={this.state.testo}
                        onChange={this.handleChange}
                        isInvalid={this.state.testoValidation}
                        />
                        <Form.Control.Feedback type="invalid">Richiesta obbligatoria</Form.Control.Feedback>
                    </Form.Group>
                

                <Form.Group>
                    <Form.Check>
                        <FormCheck.Input onChange={this.handleChangeCheckbox} defaultChecked={this.state.privacy} isInvalid={this.state.privacyValidation} type={"checkbox"} id="privacy" />
                        <FormCheck.Label  htmlFor="privacy">Acconsenso al trattamento dei dati personali avendo letto l'informativa <Link to="/privacy">disponibile qui</Link></FormCheck.Label>
                        <Form.Control.Feedback type="invalid">Per poter gestire la tua richiesta dobbiamo avere il consenso al trattamento dei tuoi dati personali</Form.Control.Feedback>                        
                    </Form.Check>
                </Form.Group>
                

                <Button 
                variant={ !this.state.buttonIsLoading ? "secondary" : "light"}
                type="button" 
                onClick={ !this.state.buttonIsLoading ? this.handleSubmit : null}
                >                    
                { !this.state.buttonIsLoading ? "Clicca per inviare la tua richiesta" : "attendere prego..."}
                </Button>

                </div>

            </Form>
        )
    }
}