import React from 'react';

// components
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';


import {Container} from 'react-bootstrap';


const scrollToTop = () => {
    let c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};


export default class Cookie extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            thispage: "cookie",
            scroll: 0,
            sidebarState: false,
            viewport: {
                width: '100%',
                height: 300,
                latitude: 7.647013,
                longitude: 45.401349,
                zoom: 8
            }
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuIconClick = this.handleMenuIconClick.bind(this);
    }

    // valuto lo scrolling e la distanza dal TOP
    handleScroll(event){        
        let offsettop = event.srcElement.scrollingElement.scrollTop;
        this.setState({ scroll: offsettop });
    }

    // il click sull'icona in ALTO nella sidebar, gestito in Topbar.js, deve mostrare il Sidebar.js.
    handleMenuIconClick(){
        console.log("handleMenuIconClick()");
        this.setState({
            sidebarState: !this.state.sidebarState
        },() => {
            console.log("nuovo val stato: "+ this.state.sidebarState);
        });
    }

    

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    render(){

        let topbarStyle = false, arrowUpToggle;
        if(this.state.scroll!==0){
            topbarStyle = true;
            arrowUpToggle = 'shown';
        }

        return(
            <div className="globalContainer">
               
                <Sidebar location={this.state.thispage} sidebarState={this.state.sidebarState} handleClose={this.handleMenuIconClick} />
                <Topbar location={this.state.thispage} minified={topbarStyle} menuMobileIconClick={this.handleMenuIconClick}  />
                <div id="arrowUpWindowManager" className={arrowUpToggle} onClick={() => scrollToTop()} >
                <i className="material-icons">
                play_for_work
                </i></div>

                
                <div className="gradient1">
                    <Container>
                        
                        <h2>Cookie Policy</h2>
                        <p><strong>ai sensi dell&rsquo;Articolo 13 del Regolamento UE 2016/679</strong></p>

<p>La presente &ldquo;Cookie Policy&rdquo; riguarda l&rsquo;utilizzo di cookie da parte di Il Gommista di Perardi Igor per rendere l&rsquo;uso della navigazione del sito corretta e pi&ugrave; semplice.<br />
A causa di eventuali modifiche al nostro sito Web o alle normative riguardanti i cookie &egrave; possibile che queste informative vengano periodicamente modificate. Siamo pertanto autorizzati a cambiare i contenuti delle informative e i cookie indicati nell&rsquo;elenco in qualsiasi momento e senza preavviso. La versione pi&ugrave; aggiornata pu&ograve; essere consultata in questa pagina Web.</p>

<p>&nbsp;</p>

<p><strong>1) COSA SONO I COOKIE E LORO DISTINZIONE</strong></p>

<p>I cookie sono piccoli file di testo, creati in automatico, usati per tracciare le sessioni di navigazione e per memorizzare informazioni specifiche riguardanti gli utenti che accedono al server.</p>

<p><strong>Cookie Tecnici: s</strong>ono utilizzati per consentire la corretta fruizione del sito web. Possono essere distinti in ragione della loro persistenza sul terminale dell&rsquo;utente (quelli che si cancellano automaticamente alla fine di ogni navigazione, &ldquo;session cookie&rdquo; o, se hanno una vita pi&ugrave; lunga, si parla di &ldquo;cookie permanenti&rdquo;). Sono inoltre utilizzati per fornire servizi o per ricordare le impostazioni per migliorare la visita e l&rsquo;esperienza dell&rsquo;utente nel sito (ad esempio, la lingua). Per l&rsquo;installazione di tali cookie non &egrave; richiesto il preventivo consenso dell&rsquo;utente.</p>

<p><strong>Cookie di Profilazione: s</strong>ono volti a creare profili relativi all&rsquo;utente e vengono utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nell&rsquo;ambito della navigazione in rete. L&rsquo;utente deve essere adeguatamente informato sull&rsquo;uso degli stessi ed esprimere cos&igrave; il proprio valido consenso, oltre a permettere in qualsiasi momento la loro disinstallazione. Questo sito non utilizza cookie di questa tipologia.</p>

<p><strong>Cookie di Terze Parti:</strong>&nbsp;si definiscono cookie di &ldquo;terze parti&rdquo; quelli forniti da soggetti terzi rispetto al proprietario del sito, che forniscono questi servizi in cambio di informazioni in merito alla visita dell&rsquo;utente. Ne discende anche per i terzi fornitori di cookie l&rsquo;obbligo di rispettare la normativa in materia. Per tale motivo si rimanda ai link delle pagine web del sito della terza parte, nelle quali l&rsquo;utente potr&agrave; trovare i moduli di raccolta del consenso ai cookie e le loro relative informative.</p>

<p><strong>2) COOKIE UTILIZZATI DA QUESTO SITO E COOKIE DI TERZE PARTI</strong></p>

<p>Considerando il funzionamento di Internet e dei siti Web, non sempre siamo in grado di controllare i cookie che vengono memorizzati da terze parti attraverso il nostro sito Web (es.: nei casi in cui la pagina Web contenga i cosiddetti elementi incorporati: testi, documenti, immagini o brevi filmati che sono archiviati altrove, ma vengono visualizzati nel o attraverso il nostro sito Web). Di conseguenza, qualora nel sito Web venisse rilevato un cookie di questo tipo non presente nell&rsquo;elenco fornito, vi preghiamo di comunicarcelo o in alternativa di contattare direttamente le terze parti interessate e chiedere informazioni sui cookie che inviano, a quali scopi, qual &egrave; la loro durata e come garantiscono la privacy degli utenti. L&rsquo;utilizzo di questi cookie &egrave; infatti disciplinato dalle regole predisposte dalle terze parti medesime, pertanto, si invitano gli Utenti a prendere visione delle informative privacy e delle indicazioni per gestire o disabilitare i cookie pubblicate nelle relative pagine web.</p>

<div className="table">
<table border="1" cellPadding="5" cellSpacing="0">
	<tbody>
		<tr>
			<td>Facebook</td>
			<td>Widgets</td>
			<td>no</td>
			<td><a rel="noopener noreferrer" href="https://www.facebook.com/help/cookies/?ref=sitefooter"  target="_blank'">https://www.facebook.com/help/cookies/?ref=sitefooter</a></td>
		</tr>
		<tr>
			<td>Google Analytics</td>
			<td>Widgets/Analitico</td>
			<td>si</td>
			<td><a rel="noopener noreferrer" href="https://tools.google.com/dlpage/gaoptout?hl=it"  target="_blank'">https://tools.google.com/dlpage/gaoptout?hl=it</a></td>
		</tr>
		<tr>
			<td>Histats</td>
			<td>Analitico</td>
			<td>si</td>
			<td><a rel="noopener noreferrer" href="http://www.histats.com/optOut.php" target="_blank">http://www.histats.com/optOut.php</a></td>
		</tr>
		<tr>
			<td>TocToc.me</td>
			<td>Widgets</td>
			<td>no</td>
			<td><a rel="noopener noreferrer" href="https://www.toctoc.me/it/cookie/" target="_blank">https://www.toctoc.me/it/cookie/</a></td>
		</tr>
	</tbody>
</table>

<p>Aggiornamento: 01/06/2018</p>
</div>

<p><strong>3) DISATTIVAZIONE E GESTIONE DEI COOKIE DAI WEB BROWSER</strong></p>

<p><strong><u>Disabilitazione dei Cookie</u></strong><strong><u>:</u></strong></p>

<p>L&#39;utente pu&ograve; sempre decidere di bloccare, eliminare, disattivare cookie o altre tecnologie simili mediante le impostazioni del proprio browser o del proprio dispositivo. Esistono molti browser diversi: ogni differente browser (ed in alcuni casi anche ogni diversa versione dello stesso browser) dispone di una propria procedura per la cancellazione dei cookie. Le preferenze relative ai cookie devono essere impostate separatamente per ciascun browser usato, poich&eacute; le funzionalit&agrave; e le opzioni offerte potrebbero variare. Di seguito si indicano i link che spiegano come gestire o disabilitare i cookie per i web browser Internet pi&ugrave; diffusi. Per tutti quelli non inseriti sar&agrave; necessario fare riferimento alle linee guida del proprio web browser.</p>

<ul>
	<li><a rel="noopener noreferrer" href="https://support.google.com/chrome/answer/95647?hl=it">Google Chrome</a></li>
	<li><a rel="noopener noreferrer" href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11">Windows IE</a></li>
	<li><a rel="noopener noreferrer" href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">Firefox</a></li>
	<li><a rel="noopener noreferrer" href="https://support.apple.com/it-it/HT201265">Safari</a></li>
	<li><a rel="noopener noreferrer" href="http://help.opera.com/Windows/10.00/it/cookies.html">Opera</a></li>
</ul>

<p>La completa disattivazione dei cookie potrebbe precludere molte funzionalit&agrave;, o la corretta navigazione o visualizzazione del sito o di altre pagine web.&nbsp;</p>

<p><strong><u>Accettazione e Rinuncia ai Cookie:</u></strong></p>

<p>Al momento del primo accesso dell&#39;utente sul sito e, in ogni caso, finch&eacute; non verr&agrave; espressa una scelta, all&#39;utente sar&agrave; chiesto di fornire il suo consenso all&#39;uso dei cookies.</p>


<p><strong>4) TITOLARE DEL TRATTAMENTO</strong></p>

<p>Il titolare del trattamento &egrave; <strong><u>Il Gommista di Perardi Igor</u></strong><u>.</u>, rappresentata dal Legale rappresentante e con sede Legale in <strong>Via Fratelli Rosselli 73 &ndash; Cuorgnè</strong> <strong>(TO)</strong></p>

<p>Per l&rsquo;esercizio dei Suoi diritti o per domande o informazioni in ordine al trattamento dei Suoi dati ed alle misure di sicurezza adottate potr&agrave; in ogni caso mettersi in contatto con la nostra Societ&agrave; mediante una richiesta con le seguenti modalit&agrave;:</p>

<p>Raccomandata a:</p>

<p><strong><u>Il Gommista di Perardi Igor</u></strong></p>

<p>Via Fratelli Rosselli, 73 - Cuorgnè, TO</p>

<p>comunicazione via e-mail all&rsquo;indirizzo: &nbsp; <strong>info@il-gommista.it</strong></p>




                    </Container>                
                </div>
               
                <Footer location={this.state.thispage} />

            </div>
        )
    }
}

