import React from 'react';

// components
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';


import {Container} from 'react-bootstrap';


const scrollToTop = () => {
    let c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};


export default class Ebike extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            thispage: "ebike",
            scroll: 0,
            sidebarState: false,
            viewport: {
                width: '100%',
                height: 300,
                latitude: 7.647013,
                longitude: 45.401349,
                zoom: 8
            },
            form:{

            }
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuIconClick = this.handleMenuIconClick.bind(this);
    }

    // valuto lo scrolling e la distanza dal TOP
    handleScroll(event){        
        let offsettop = event.srcElement.scrollingElement.scrollTop;
        this.setState({ scroll: offsettop });
    }

    // il click sull'icona in ALTO nella sidebar, gestito in Topbar.js, deve mostrare il Sidebar.js.
    handleMenuIconClick(){
        console.log("handleMenuIconClick()");
        this.setState({
            sidebarState: !this.state.sidebarState
        },() => {
            console.log("nuovo val stato: "+ this.state.sidebarState);
        });
    }

    

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    render(){

        let topbarStyle = false, arrowUpToggle;
        if(this.state.scroll!==0){
            topbarStyle = true;
            arrowUpToggle = 'shown';
        }

        return(
            <div className="globalContainer">
               
                <Sidebar location={this.state.thispage} sidebarState={this.state.sidebarState} handleClose={this.handleMenuIconClick} />
                <Topbar location={this.state.thispage} minified={topbarStyle} menuMobileIconClick={this.handleMenuIconClick}  />
                <div id="arrowUpWindowManager" className={arrowUpToggle} onClick={() => scrollToTop()} >
                <i className="material-icons">
                play_for_work
                </i></div>

                
                <div className="gradient1">
                        <Container>
                            <h2 className="text-center">NOLEGGIO E VENDITA E-BIKE - Biciclette a pedalata assistita</h2>
                            <p className="text-center">Se sei amante della natura e vuoi dotarti di un mezzo che può portati realmente ovunque, grazie alla pedalata assistita, vieni a trovarci!</p>
                            <p className="text-center">Contattaci per sapere le promozioni del momento ed i mezzi disponibili in pronta consegna!</p>
                            
                            <div className="brandContainer">
                                <div className='logoContainer'>
                                    <img src='/noleggio-ebike/alpek/logo.png' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                </div>
                                <div>
                                    <h4>E-BIKE URBAN</h4>
                                    <ul className='catalogo-ebike'>
                                        <li>
                                            <a href='https://alpekbike.it/prodotto/bici-da-citta-ryse-m200-e-urban-torque-sensor/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/alpek/ebike-uomo-donna-ryse-caffè-001.jpeg' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://alpekbike.it/prodotto/bici-da-citta-ryse-m300-e-urban-speed-sensor/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/alpek/ebike-uomo-donna-ryse-tiffany-001-518x518.jpeg' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h4>E-BIKE TREKKING</h4>
                                    <ul className='catalogo-ebike'>
                                        <li>
                                            <a href='https://alpekbike.it/prodotto/bici-e-trekking-drakar-2/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/alpek/Drakar-1000x1000-3-518x518.jpeg' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://alpekbike.it/prodotto/trekking-e-bike-trecy/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/alpek/Trecy-Menta-1000x1000-1-518x518.jpeg' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h4>E-BIKE MTB</h4>
                                    <ul className='catalogo-ebike'>
                                        <li>
                                            <a href='https://alpekbike.it/prodotto/mtb-e-bike-evoke-comp-2/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/alpek/EVOKE-black-2-518x518.jpeg' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://alpekbike.it/prodotto/mtb-e-bike-evoke-pro-copia/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/alpek/EVOKE-tricolor-1000x667-1-518x518.jpeg' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>

                                        <li>
                                            <a href='https://alpekbike.it/prodotto/mtb-e-bike-nitro-copia/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/alpek/Nitro-Turchese-1000x1000-2-518x518.jpeg' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://alpekbike.it/prodotto/mtb-e-bike-runa/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/alpek/RUNA-1000x667-1-518x518.jpeg' alt="Alpek Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>


                            <div className="brandContainer">
                                <div className='logoContainer'>
                                    <img src='/noleggio-ebike/osmegroup/logo.png' alt="OsmeGroup Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                </div>
                                <div>
                                    <h4>E.GRAVEL</h4>
                                    <ul className='catalogo-ebike'>
                                        <li>
                                            <a href='https://osmegroup.com/os1/' rel="noopener noreferrer" target='_blank'  >
                                                <img src='/noleggio-ebike/osmegroup/e-gravel_os1.jpeg' alt="Osme Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://osmegroup.com/os3/' rel="noopener noreferrer" target='_blank' >
                                                <img src='/noleggio-ebike/osmegroup/e-gravel_os3.jpeg' alt="Osme Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <h4>OS.ROAD e AL ROAD</h4>
                                    <ul className='catalogo-ebike'>
                                        <li>
                                            <a href='https://osmegroup.com/os-road/' rel="noopener noreferrer" target='_blank'  >
                                                <img src='/noleggio-ebike/osmegroup/osroad.jpeg' alt="Osme Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://osmegroup.com/al-road-8/' rel="noopener noreferrer" target='_blank'  >
                                                <img src='/noleggio-ebike/osmegroup/alroad.jpeg' alt="Osme Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <h4>E-MTB</h4>
                                    <ul className='catalogo-ebike'>
                                        <li>
                                            <a href='https://osmegroup.com/osm/' rel="noopener noreferrer" target='_blank'  >
                                                <img src='/noleggio-ebike/osmegroup/mtb-osm.jpeg' alt="Osme Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://osmegroup.com/osh/' rel="noopener noreferrer" target='_blank'  >
                                                <img src='/noleggio-ebike/osmegroup/mtb-osh.jpeg' alt="Osme Ebike Noleggio in Canavese" className="img-fluid img-logo-ebike img-border-radius" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                            
                        </Container>
                    </div>
               
                <Footer location={this.state.thispage} />

            </div>
        )
    }
}

