import React from 'react';
import { Link } from "react-router-dom";

// components
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Slideshow from 'react-slidez';

var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

let marchi_auto = [
    {
        title:"CEAT",
        url:"https://it.ceat.com/"
    },
    {
        title:"Continental",
        url:"https://www.continental-pneumatici.it/auto"
    },
    {
        title:"Vredestein",
        url:"https://www.vredestein.it/car-suv-van/tyre-finder/"
    },
    {
        title:"Pirelli",
        url:"https://www.pirelli.com/tyres/it-it/auto/pneumatici-per-la-tua-auto/catalogo"
    },
    {
        title:"Nokian",
        url:"https://www.nokiantyres.it/pneumatici/veicolo-trasporto-persone/pneumatici-invernali/"
    },
    {
        title:"Barum",
        url:"https://www.barum-pneumatici.it/auto/il-brand"
    },
    {
        title:"Bridgestone",
        url:"https://www.bridgestone.it/auto-suv-van/catalogo-pneumatici/?referrer=https%3A%2F%2Fwww.google.com%2F"
    },
    {
        title:"Yokohama",
        url:"https://www.yokohama.it/"
    },
    {
        title:"Michelin",
        url:"https://www.michelin.it/"
    },
    {
        title:"Kleber",
        url:"https://kleber.it"
    },
    {
        title:"BF Goodrich",
        url:"https://www.bfgoodrich.it/"
    },
    {
        title:"Firestone",
        url:"https://www.firestone.it/auto-suv-4x4/?referrer=https%3A%2F%2Fwww.google.com%2F"
    },
    {
        title:"Hankook",
        url:"https://www.hankooktire.com/it/"
    },
    {
        title:"Dunlop",
        url:"https://www.dunlop.eu/it_it/consumer.html#/"
    },
    {
        title:"Goodyear",
        url:"https://www.goodyear.eu/it_it/consumer/tires.html"
    },
    {
        title:"Toyo",
        url:"https://www.toyo.it/"
    }
]

let marchi_moto = [
    {
        title: "Michelin",
        url: "https://www.michelin.it/motorbike/home-moto"
    },
    {
        title: "Pirelli",
        url: "https://www.pirelli.com/tyres/it-it/moto/homepage"
    },
    {
        title: "Continental",
        url:"https://www.continental-pneumatici.it/moto"
    },
    {
        title: "Bridgestone",
        url:"https://www.bridgestone.it/pneumatici-moto/?referrer=https%3A%2F%2Fwww.google.com%2F"
    },
    {
        title: "Nankang",
        url:"http://www.nankang-tyre.com/index.php?lang=en-US"
    },
    {
        title: "Dunlop",
        url: "https://www.dunlop.eu/it_it/motorcycle.html#/"
    }
]

let cerchi = [
    {
        title:"OZ Racing",
        url: "https://www.ozracing.com/it/"
    },
    {
        title:"MAK",
        url:"https://www.makwheels.it/"
    },
    {
        title:"TecnoMagnesio",
        url:"http://www.mimtecnomagnesio.it/"
    },
    {
        title:"MIM",
        url:"http://www.mimtecnomagnesio.it/"
    },
    {
        title: "Brock",
        url:"http://www.mimtecnomagnesio.it/index.php/brock-wheels-omologati-nad-in-italia/"
    },
    {
        title: "AVUS",
        url:"https://www.avusracing.it/"
    }
]


const scrollToTop = () => {
    let c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};

export default class Homepage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            thispage: "homepage",
            scroll: 0,
            sidebarState: false,
            viewport: {
                width: '100%',
                height: 300,
                latitude: 7.647013,
                longitude: 45.401349,
                zoom: 8
            }
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuIconClick = this.handleMenuIconClick.bind(this);
    }
    
    // valuto lo scrolling e la distanza dal TOP
    handleScroll(event){        
        let offsettop = event.srcElement.scrollingElement.scrollTop;
        this.setState({ scroll: offsettop });
    }

    // il click sull'icona in ALTO nella sidebar, gestito in Topbar.js, deve mostrare il Sidebar.js.
    handleMenuIconClick(){
        console.log("handleMenuIconClick()");
        this.setState({
            sidebarState: !this.state.sidebarState
        },() => {
            console.log("nuovo val stato: "+ this.state.sidebarState);
        });
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);


        mapboxgl.accessToken = 'pk.eyJ1IjoiYWxlbXV4IiwiYSI6ImNrMWo1aXY2bzA3dDUzaGw5aHV5YTRldGkifQ.Q-lWrDVmyHnvKgArTzXAig';
        var map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [ 7.647013,45.401349],            
            zoom: 12,
           
            layout: {
                "icon-image": "icon-15",
                "icon-allow-overlap": true      
            }
        });
        map.scrollZoom.disable();
        map.boxZoom.disable();
        map.dragRotate.disable();
        map.dragPan.disable();
        map.keyboard.disable();
        map.doubleClickZoom.disable();
        map.touchZoomRotate.disable();


        map.on('load', function() {
            map.loadImage('/images/map.png', function(error, image) {
                if (error) throw error;
                map.addImage('cat', image);
                map.addLayer({
                    "id": "points",
                    "type": "symbol",
                    "source": {
                        "type": "geojson",
                        "data": {
                            "type": "FeatureCollection",
                            "features": [{
                                "type": "Feature",
                                "geometry": {
                                    "type": "Point",
                                    "coordinates": [ 7.647013,45.401349]
                                }
                            }]
                        }
                    },
                    "layout": {
                        "icon-image": "cat",
                        "icon-size": 1
                    }
                });
            });
        });



    }
    
    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    

    render(){
        let topbarStyle = false, arrowUpToggle;
        if(this.state.scroll!==0){
            topbarStyle = true;
            arrowUpToggle = 'shown';
        }

        

        return(
            <div className="globalContainer">
                

                <Sidebar location={this.state.thispage} sidebarState={this.state.sidebarState} handleClose={this.handleMenuIconClick} />

                <div id="arrowUpWindowManager" className={arrowUpToggle} onClick={() => scrollToTop()} >
                <i className="material-icons">
                play_for_work
                </i></div>

                <Topbar location={this.state.thispage} minified={topbarStyle} menuMobileIconClick={this.handleMenuIconClick}  />


                <div className="SlideShowContainer"> 
                <Slideshow
                    autoplay
                    enableKeyboard
                    //useDotIndex
                    slideInterval={8000}
                    defaultIndex={1}
                    slides={['/images/IMG_6882.jpg', '/images/IMG_6891.jpg']}
                    effect={'fade'}
                    height={'450px'}
                    width={'100%'}
                />
                </div>

                <div id="homepageContainer">
                    
                    <div className="gradient1">
                    <Container>                                                                            
                    <h1 className="title text-center">Il Gommista di Perardi Igor<br /><span className="bigger">Benvenuti!</span></h1>
                    <p className="text-center">Vienici a trovare nella nostra nuova sede in Via Fratelli Rosselli, 73 a Cuorgnè (TO)</p>
                    <p className="text-center">Nella nostra nuova sede di 500mq potrai trovare le migliori marche e un trattamento professionale per gli pneumatici della tua auto!</p>
                    <div className="homeImageContainer"><img src="/images/IMG_6896.jpg" alt="Le migliori marche di cerchi e pneumatici le trovi da Il Gommista di Perardi Igor" /></div>
                    <h2 className="text-center">Prenotazione montaggio</h2>
                    <p className="text-center">Telefona al 0124/651669, scrivi a info@il-gommista.it, oppure <Link to={'/contatti'}>compila il nostro form di richiesta informazioni</Link> per prendere appuntamento!</p>
                    
                    </Container>
                    </div>


                    <div className="gradient1">
                        <Container>
                            <h2 className="text-center">E-BIKE - Biciclette a pedalata assistita</h2>
                            <p className="text-center">Se sei amante della natura e vuoi dotarti di un mezzo che può portati realmente ovunque, grazie alla pedalata assistita, vieni a trovarci!</p>
                            <p className="text-center">Abbiamo una vasta gamma di <strong>E-BIKE</strong> a <strong>noleggio</strong> e <strong>vendita</strong>: mezzi nuovi, tecnologicamente all'ultimo grido, il meglio del Made in Italy: <a href="/ebike">clicca qui per consultare i modelli che trattiamo</a> </p>
                            <div className='text-center'>
                                <a href="/ebike" title='Noleggia la tua EBike in Canavese'>
                                <img src="/images/canavese-ebike-noleggio-ilgommista.jpg" alt='E-BIKE CANAVESE' className='img-border marginBottom10 img-max' />                                
                                </a>
                            </div>
                        </Container>
                    </div>




                    <div className="gommeAutoContainer">
                    <Container>
                    <h1 className="title text-center"><span className="bigger">Pneumatici auto</span></h1>
                    <p className="text-center">Le migliori marche e le migliori attrezzature per curare nei minimi dettagli il montaggio degli pneumatici sui tuoi cerchi</p>
                    <div className="brand_container">
                    <ul>
                        {marchi_auto.map((item, index) => <Brand key={index} title={item.title} url={item.url} />)}
                    </ul>
                    
                    </div>
                    </Container>
                    </div>




                    <div className="gommeMotoContainer">
                        <Container>
                        <h1 className="title text-center"><span className="bigger">Pneumatici moto</span></h1>
                        <p className="text-center">Divertimento in totale sicurezza: per gli pneumatici della tua moto passa da noi</p> 
                        <div className="brand_container">
                        <ul>
                            {marchi_moto.map((item, index) => <Brand key={index} title={item.title} url={item.url} />)}
                        </ul>
                        </div>
                        </Container>                   
                    </div>


                    <div className="gommeCerchiContainer">
                        <Container>
                        <h1 className="title text-center"><span className="bigger">Cerchi</span></h1>
                        <p className="text-center">La tua auto si merita cerchi in lega di qualità: da noi puoi trovare quello che cerchi al miglior prezzo</p> 
                        <div className="brand_container">
                        <ul>
                            {cerchi.map((item, index) => <Brand key={index} title={item.title} url={item.url} />)}
                        </ul>
                        </div>
                        </Container>                   
                    </div>


                    <div id="map" className="map"></div>

                    <Footer location={this.state.thispage} />
                    

                </div>
            </div>
        )
    }
}


class Brand extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            title: "",
            url:"",
            props: props,
        }
    }
    
    render(){
       
        return(
            <li>
                <a href={this.state.props.url} rel="noreferrer noopener" target='_blank'>{this.state.props.title}</a>
            </li>
        )
    }
}