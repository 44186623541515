import React from 'react';

// components
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';


import {Container} from 'react-bootstrap';


const scrollToTop = () => {
    let c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};


export default class Privacy extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            thispage: "privacy",
            scroll: 0,
            sidebarState: false,
            viewport: {
                width: '100%',
                height: 300,
                latitude: 7.647013,
                longitude: 45.401349,
                zoom: 8
            },
            form:{

            }
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuIconClick = this.handleMenuIconClick.bind(this);
    }

    // valuto lo scrolling e la distanza dal TOP
    handleScroll(event){        
        let offsettop = event.srcElement.scrollingElement.scrollTop;
        this.setState({ scroll: offsettop });
    }

    // il click sull'icona in ALTO nella sidebar, gestito in Topbar.js, deve mostrare il Sidebar.js.
    handleMenuIconClick(){
        console.log("handleMenuIconClick()");
        this.setState({
            sidebarState: !this.state.sidebarState
        },() => {
            console.log("nuovo val stato: "+ this.state.sidebarState);
        });
    }

    

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    render(){

        let topbarStyle = false, arrowUpToggle;
        if(this.state.scroll!==0){
            topbarStyle = true;
            arrowUpToggle = 'shown';
        }

        return(
            <div className="globalContainer">
               
                <Sidebar location={this.state.thispage} sidebarState={this.state.sidebarState} handleClose={this.handleMenuIconClick} />
                <Topbar location={this.state.thispage} minified={topbarStyle} menuMobileIconClick={this.handleMenuIconClick}  />
                <div id="arrowUpWindowManager" className={arrowUpToggle} onClick={() => scrollToTop()} >
                <i className="material-icons">
                play_for_work
                </i></div>

                
                <div className="gradient1">
                    <Container>
                        
                        <h2>Trattamento dei dati personali</h2>
                        <h5>Informativa ai sensi dell’Articolo 13 del Regolamento UE 2016/679</h5>
                        <p>Ai sensi del Regolamento (UE) 2016/679 (di seguito "Regolamento"), questa pagina descrive le modalità di trattamento dei dati personali degli utenti che consultano il sito web www.il-gommista.it  
Le presenti informazioni non riguardano altri siti, pagine o servizi online raggiungibili tramite link ipertestuali eventualmente pubblicati nel sito ma riferiti a risorse esterne al dominio il-gommista.it.</p>



                        <p>1. TITOLARE DEL TRATTAMENTO</p>

<p>Il titolare del trattamento è Il Gommista di Perardi Igor, rappresentata dal Legale rappresentante, con sede Legale in Via Fratelli Rosselli, 73, Cuorgnè TO.</p>

<p>2. TIPI DI DATI TRATTATI</p>

<p>A seguito della consultazione del sito possono essere trattati dati relativi a persone fisiche identificate o identificabili.</p>


<p>2.1 Dati comunicati dagli utenti</p>

<p>All’interno del sito internet di competenza della Società si delineano differenti possibilità e modalità di contatto della stessa per l’utente.<br />

Premesso che l’invio di dati da parte dell’utente è volontario e facoltativo, egli dispone di svariate possibilità di interazione la cui instaurazione è imprescindibile dall’acquisizione di dati personali.<br />

Di seguito si delinea un elenco aggiornato delle varie modalità di contatto previste dal sito:<br />

Contatto telefonico: l’utente può contattare la Società mediante i contatti telefonici presenti sul sito (sia con link diretto, che in via indiretta mediante acquisizione di recapito), l’instaurazione di un contatto telefonico può richiedere la comunicazione di dati personali dell’utente per finalità quali, a titolo esemplificativo, prenotazione di appuntamenti e/o di tests drive;<br />
Contatto tramite posta elettronica: il sito fornisce all’utente la possibilità di avanzare richieste su una svariata tipologia di item tramite i contatti e-mail, raggiungibili con link diretti, all’interno della pagina web. La Società acquisisce, in tal modo, l’indirizzo di posta elettronica dell’utente ed, eventualmente, se questi risultano contenuti nel testo della corrispondenza, ulteriori dati personali e particolari;<br />
Invio CV tramite posta elettronica: il sito fornisce all’utente la possibilità di trasmettere il proprio curriculum per autocandidarsi ad eventuali posizioni lavorative. La Società acquisisce, in tal modo, l’indirizzo di posta elettronica dell’utente ed il CV completo di tutte le informazioni;<br />
Richiesta informazioni sui beni offerti dalla Società: l’utente dispone della possibilità di inoltro della propria richiesta compilando l’apposito form on-line, mediante il quale la Società acquisisce i dati personali (anagrafici e di contatto) funzionali all’esaurimento delle richieste inviate;<br />
Prenotazione tagliando on-line: l’utente comunica alla Società i propri dati personali e quelli del proprio autoveicolo funzionali alla prenotazione;<br />
Chat on-line: il sito internet consente all’utente di contattare un operatore della Società che, nel corso della conversazione/corrispondenza, potrebbe richiedere all’interlocutore l’invio di dati personali, funzionali all’erogazione dei servizi richiesti;<br />
Contatti mediante social network: il sito internet presenta link di collegamento diretto alle pagine social gestite dalla Società (Instagram e Facebook); servendosi delle pagine social l’utente può contattare la Società per svariate finalità, inclusa la possibilità di apporre commenti o reactions al loro interno.<br />
L’interazione social comporta l’acquisizione di dati personali comunicati volontariamente dagli utenti;<br /><br />

Dati relativi alla posizione geografica: l’utente può accedere con link diretto alla funzionalità Google+ che, oltre a fornire la posizione relativa all’ubicazione della Società, consente l’acquisizione della posizione dell’utente nel momento in cui lo stesso ha attivato la geolocalizzazione sul proprio dispositivo di connessione e impiega la funzione google maps.
</p>

<p>2.2 Dati di navigazione</p>

<p>I sistemi informatici e le procedure software preposte al funzionamento di questo sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di internet.
<br />
In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer e dei terminali utilizzati dagli utenti, gli indirizzi in notazione URI/URL delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente.
<br />
Tali dati, necessari per la fruizione dei servizi web, vengono trattati allo scopo di:
<br />
ottenere informazioni statistiche sull'uso dei servizi (pagine più visitate, numero di visitatori per fascia oraria o giornaliera, aree geografiche di provenienza, ecc.);<br />
controllarne il corretto funzionamento e migliorare l'esperienza di navigazione degli Utenti.<br />
Tali informazioni potrebbero, inoltre, essere utilizzate per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del Sito.<br /><br />

I dati di navigazione vengono cancellati immediatamente dopo l’elaborazione (salve eventuali necessità di accertamento di reati da parte dell'Autorità giudiziaria).

</p>
<p>
2.3 Informazioni raccolte tramite cookies</p>
<p>
Nel sito sono utilizzati sistemi di web tracking, quali i cookies. Si tratta di registrazioni informatiche di informazioni trasmesse da un web server al computer dell’utente per la futura identificazione di tale computer al momento di future visite sullo stesso sito web. Tali strumenti contribuiscono a facilitare l'analisi del traffico su web, permettono la corretta operatività del sito e consentono alle applicazioni web di inviare informazioni a singoli utenti.
<br />
Può trovare la nostra Informativa sui Cookie qui.
</p>
 
<p>
3. FINALITA’ DEL TRATTAMENTO</p>
<p>
In particolare Il Gommista di Perardi Igor può raccogliere e trattare i dati degli Utenti per le seguenti finalità:
<br />
instaurazione ed esecuzione dei rapporti contrattuali e obblighi conseguenti, ivi inclusa la comunicazione relativa ai servizi (ad esempio per dare esecuzione agli ordini di acquisto o per rispondere ad eventuali richieste di informazioni e di servizi);<br />
finalità di marketing, comunicazione pubblicitaria, trasmissione di periodiche newsletter informative realizzate mediante modalità automatizzata di contatto (posta elettronica) ed eventuale invio di gadget per finalità legate alla fidelizzazione dell’utente;<br />
finalità promozionali/pubblicitarie mediante condivisione su social network di immagini fotografiche e video, acquisiti nel corso di eventi organizzati dalla Società;<br />
in conformità alle richieste giuridicamente vincolanti per adempiere un obbligo legale, regolamenti o provvedimenti dell’autorità giudiziaria, nonché per difendere un diritto in sede giudiziaria.<br />
per l’eventuale inoltro di comunicazioni inerenti l’organizzazione di eventi, presentazioni ed altre attività predisposte a fini pubblicitari;<br />
per il conferimento degli stessi ai nostri partner commerciali, al fine di ampliare la varietà dei servizi e delle comunicazioni offerte;<br />
elaborazione di studi e ricerche di mercato.
 </p>
 <p>
4. MODALITÀ DEL TRATTAMENTO</p>


<p>
Il trattamento dei dati personali avverrà nel pieno rispetto dei principi di riservatezza, correttezza, necessità, pertinenza, liceità e trasparenza imposti dal GDPR.
<br />
Il trattamento dei dati personali verrà effettuato attraverso strumenti automatizzati (ad es. utilizzando procedure e supporti elettronici) e/o manualmente (ad es. su supporto cartaceo), in conformità con le disposizioni normative vigenti. Idonee misure di sicurezza saranno osservate per prevenire la perdita dei dati personali, usi illeciti o non corretti degli stessi ovvero accessi non autorizzati.

</p>
<p>
5. NATURA OBBLIGATORIA O FACOLTATIVA DEL CONFERIMENTO DEI DATI</p>
<p>
Finalità a:
<br />
il conferimento da parte dell’utente dei propri dati personali è necessario per evadere richieste e domande dell’utente o per fornirgli i beni e servizi domandati.
<br />
Il mancato conferimento di tali dati impedisce, pertanto, all’utente di ricevere le informazioni, i beni e/o i servizi richiesti. 
<br />
Il trattamento dei dati in questione, in quanto volto a evadere richieste dell’interessato e/o a concludere e dare esecuzione a contratti conclusi con lo stesso, non necessita dell’espresso consenso dell’utente (presupposto di adempimento degli obblighi contrattuali).
<br />
Finalità b:
<br />
il conferimento da parte dell’utente dei dati personali per finalità di marketing è, invece, facoltativo ed il relativo trattamento può avvenire soltanto con l’espresso consenso dell’utente.
<br />
Il mancato conferimento dei dati personali per tali finalità non impedisce all’utente il ricevimento delle informazioni o la fornitura dei beni e/o servizi richiesti.
<br />
Il consenso può essere revocato in ogni momento.
<br />
Finalità c:
<br />
il conferimento da parte dell’utente dei dati personali (immagini e/o video) per finalità promozionali è, facoltativo ed il relativo trattamento può avvenire soltanto con l’espresso consenso dell’utente.
<br />
Il mancato conferimento dei dati personali per tali finalità non impedisce all’utente il ricevimento delle informazioni o la fornitura dei beni e/o servizi richiesti.
<br />
Il consenso può essere revocato in ogni momento.
<br />
Finalità d:
<br />
la Società raccoglie i Suoi Dati di contatto per adempiere un obbligo legale e/o per difendere un proprio diritto in sede giudiziaria (presupposto per il trattamento: obblighi di legge, cui la Società è obbligata a ottemperare).
<br />
Finalità e:
<br />
la Società richiede il Suo consenso all’impiego dei dati personali in nostro possesso, in particolare i Suoi dati anagrafici e di contatto, per trasmetterle inviti ed informazioni, relativamente agli eventi organizzati a fini commerciali e pubblicitari, per il quale risulta gradita la sua partecipazione.
<br />
Le ricordiamo che la sua espressione di consenso è facoltativa e può esercitare il suo diritto di revoca al trattamento in qualsiasi momento.
<br />
Finalità f:
<br />
a seguito di Suo espresso consenso all’impiego dei suoi dati per questa particolare finalità di marketing, al fine di garantirle una più ampia gamma di servizi e comunicazioni commerciali ed implementare la sua disponibilità di informazioni in merito a quanto offerto dal mercato, la Società fornisce i dati personali da Lei comunicati ai nostri partner commerciali.
<br />
Le ricordiamo che la sua espressione di consenso è facoltativa e può esercitare il suo diritto di revoca al trattamento in qualsiasi momento.
<br />
Finalità g:
<br />
per migliorare costantemente i servizi offerti dalla Società in base agli interessi e le richieste del cliente, a seguito di Suo volontario consenso, tratteremo i Suoi dati personali per l’elaborazione di analisi e studi di mercato, valutando attentamente le sue preferenze ed inclinazioni.
<br />
Le ricordiamo che la sua espressione di consenso è facoltativa e può esercitare il suo diritto di revoca al trattamento in qualsiasi momento.
</p>


<p>
6. AMBITO DI COMUNICAZIONE E DIFFUSIONE DEI DATI</p>
<p>
Per il perseguimento delle finalità sopraccitate e nei limiti di quanto a ciò strettamente necessario, i dati personali dell’utente potranno essere portati a conoscenza di dipendenti e collaboratori di Il Gommista di Perardi Igor, nominati responsabili e/o incaricati del trattamento, in ragione e nei limiti delle mansioni ed incarichi ai medesimi assegnati.
<br />
Ad esclusione degli incaricati al trattamento interni alla Società, potranno venire a conoscenza dei dati personali dell’utente i soggetti che svolgono attività connesse, strumentali e/o di supporto alle attività di Il Gommista di Perardi Igor e a cui quest’ultima potrebbe rivolgersi – previa nomina quali responsabili del trattamento – per l’espletamento delle predette attività, nei limiti di quanto a ciò strettamente necessario e nel rispetto delle finalità di cui sopra.
<br />
Il Gommista di Perardi Igor potrebbe, infine, comunicare i dati personali dell’utente – a seguito di suo espresso consenso – ad Aziende e Società che figurano in qualità di partner commerciali con la finalità di fornire servizi e comunicazioni commerciali aggiuntive.
<br />
Informazioni più dettagliate sui nominativi dei Responsabili del trattamento e sui partner commerciali coinvolti nel trattamento dei dati personali dell’utente potranno essere richieste inviando una mail al seguente indirizzo: info@il-gommista.it
</p>

<p>
7. CONSERVAZIONE DEI DATI</p>
<p>
I dati da Lei forniti saranno trattati per tutta la durata del rapporto contrattuale in essere ed ulteriormente conservati esclusivamente per il periodo previsto per l’adempimento di obblighi di legge, sempre che non risulti necessario conservarli ulteriormente per difendere o far valere un diritto o per adempiere a eventuali ulteriori obblighi di legge o ordini delle Autorità.
</p>
<p>
8. I DIRITTI DEGLI INTERESSATI </p>
<p>
In relazione al trattamento dei suoi dati personali, ha diritto di chiedere a Il Gommista di Perardi Igor:
<br />
l’accesso: può chiedere conferma che sia o meno in essere un trattamento di dati che la riguardano, oltre a maggiori chiarimenti circa le informazioni di cui alla presente Informativa, nonché di ricevere i dati stessi, nei limiti della ragionevolezza;
<br />la rettifica: può chiedere di rettificare o integrare i dati che ci ha fornito o comunque in nostro possesso, qualora inesatti;
<br />la cancellazione: può chiedere che i suoi dati vengano cancellati, qualora non siano più necessari alle nostre finalità o laddove non vi siano contestazioni o controversie in essere, in caso di revoca del consenso o sua opposizione al trattamento, in caso di trattamento illecito, ovvero qualora sussista un obbligo legale di cancellazione; 
<br />la limitazione: può chiedere la limitazione del trattamento dei suoi dati personali, quando ricorre una delle condizioni di cui all’art. 18 del GDPR; in tal caso, i suoi dati non saranno trattati, salvo che per la conservazione, senza il suo consenso fatta eccezione per quanto esplicitato nel medesimo articolo al comma 2.
<br />l’opposizione: può opporsi in qualunque momento al trattamento dei suoi dati sulla base di un nostro legittimo interesse, salvo che vi siano nostri motivi legittimi per procedere al trattamento che prevalgano sui suoi, per esempio per l’esercizio o la nostra difesa in sede giudiziaria; la sua opposizione prevarrà sempre e comunque sul nostro interesse legittimo a trattare i suoi dati per finalità di marketing;
<br />la portabilità: può chiedere di ricevere i suoi dati, o di farli trasmettere ad altro titolare da lei indicato, in un formato strutturato, di uso comune e leggibile da dispositivo automatico.
<br />Inoltre, ai sensi dell’art. 7, par. 3 del GDPR, La informiamo che può esercitare in qualsiasi momento il suo diritto di revoca del consenso, senza che venga pregiudicata la liceità del trattamento basata sul consenso prestato antecedentemente. 

<br />La informiamo che ha, altresì, diritto di proporre reclamo dinanzi all’Autorità di Controllo, che in Italia è il Garante per la Protezione dei Dati Personali.
</p>

<p>

Per l’esercizio dei Suoi diritti o per domande o informazioni in ordine al trattamento dei Suoi dati ed alle misure di sicurezza adottate potrà in ogni caso mettersi in contatto con la nostra Società mediante una richiesta con le seguenti modalità:
<br /><br />
 

<strong>Raccomandata a/r all’indirizzo:</strong>  <br /> 
Il Gommista di Perardi Igor, Via Fratelli Rosselli, 73, Cuorgnè TO<br /><br />
<strong>Comunicazione via e-mail all’indirizzo:</strong> <br />info@il-gommista.it

  </p>                      
                    </Container>                
                </div>
               
                <Footer location={this.state.thispage} />

            </div>
        )
    }
}

