import React from 'react';
import { Link } from "react-router-dom";

export default class Sidebar extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            globalClass: false
        }
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
    }

    handleCloseClick(){
        console.log("Click per chiusura sidebar");
        
    }

    handleStateChange(status){
        this.setState({
            globalClass: status
        });
    }

    componentDidUpdate(){
        //console.log("Sidebar = didUpdate = "+ this.props.sidebarState);
        //this.handleStateChange(this.props.sidebarState);
    }

    componentDidMount(){
        //console.log("Sidebar = didMount = "+ this.props.sidebarState);
    }

    render(){
        //console.log("Sidebar = render = "+ this.props.sidebarState);
        let localClass = '';
        if(!this.props.sidebarState){
            localClass = 'hidden';
        }
        let page = this.props.location;
        let class_homepage = '', class_contatti='';
        switch(page){
            case "homepage":
                class_homepage = "active";
                break;
            case "contatti":
                class_contatti = "active";
                break;
            default:
                break;
        }

        return(
            <div id="sidebar" className={localClass} aria-hidden="true">
                <div className="sidebar_closer"><i className="material-icons" onClick={() => this.props.handleClose()}>arrow_forward_ios</i></div>
                <div className="container">
                    <ul>
                        <li className={class_homepage}><Link to={'/'}>Homepage</Link></li>
                        <li className={class_contatti}><Link to={'/contatti'}>Contatti</Link></li>
                    </ul>
                </div>
                <div className="sidebar_logo"></div>
            </div>
        )
    }

}